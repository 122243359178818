import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"

import BlogCard from "./BlogCard"
import NewsletterForm from "./NewsletterForm"

import work from "../assets/home-work.png"

const BlogList = () => (
    <StaticQuery
        query={graphql`
            query {
                allMarkdownRemark(
                    sort: { fields: [frontmatter___date], order: DESC }
                    limit: 1
                    filter: {
                        frontmatter: {
                            feature_image: { publicURL: { ne: null } }
                        }
                    }
                ) {
                    edges {
                        node {
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                                description
                                feature_image {
                                    publicURL
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={data => {
            console.log(data)
            return (
                <div className="writings-container">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1440 320"
                        style={{ marginBottom: "-10px" }}
                    >
                        <path
                            fill="#eff9fb"
                            fill-opacity="1"
                            d="M0,160L34.3,144C68.6,128,137,96,206,117.3C274.3,139,343,213,411,245.3C480,277,549,267,617,256C685.7,245,754,235,823,229.3C891.4,224,960,224,1029,240C1097.1,256,1166,288,1234,298.7C1302.9,309,1371,299,1406,293.3L1440,288L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"
                        ></path>
                    </svg>
                    <div style={{ backgroundColor: "var(--box-bg-color)" }}>
                        <div className="writings-wrapper">
                            <div className="writings-container-desc">
                                <div className="writings-desc">
                                    <h4 className="home-header">Blog</h4>
                                    <p>
                                        Thoughts primarily on software
                                        development with other fun tech stuff
                                        sprinkled in.
                                    </p>
                                </div>
                            </div>

                            {data.allMarkdownRemark.edges.map(
                                ({ node }, key) => {
                                    return (
                                        <BlogCard
                                            key={key}
                                            src={
                                                node.frontmatter.feature_image
                                                    .publicURL
                                            }
                                            link={node.fields.slug}
                                            title={node.frontmatter.title}
                                            description={
                                                node.frontmatter.description
                                            }
                                        />
                                    )
                                }
                            )}
                        </div>
                    </div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 1440 320"
                    >
                        <path
                            fill="#eff9fb"
                            fill-opacity="1"
                            d="M0,192L34.3,170.7C68.6,149,137,107,206,106.7C274.3,107,343,149,411,165.3C480,181,549,171,617,160C685.7,149,754,139,823,138.7C891.4,139,960,149,1029,138.7C1097.1,128,1166,96,1234,96C1302.9,96,1371,128,1406,144L1440,160L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"
                        ></path>
                    </svg>
                </div>
            )
        }}
    />
)

export default function Home() {
    return (
        <div className="home">
            <header className="header">
                <div>
                    <h1>Hi! I’m Ananya and I’m a frontend developer.</h1>
                    <p>
                        I bring passion and expertise in building engaging
                        digital products.
                    </p>
                </div>
                <svg
                    width="188"
                    height="308"
                    viewBox="0 0 188 308"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="face-illustration"
                >
                    <g id="Face illustration">
                        <g id="Face">
                            <path
                                id="Vector 42"
                                d="M21.2933 198.562C21.2933 212.169 20.8257 226.463 24.4269 239.803C27.7854 252.244 33.7165 265.225 41.2939 276.004C50.2124 288.69 66.5717 297.433 81.2727 304.553C88.6682 308.135 98.6842 305.802 106.874 305.525C112.573 305.333 118.921 308.073 124.409 306.523C128.222 305.446 131.75 303.292 135.376 301.795C141.874 299.113 146.897 293.786 151.926 289.361C159.453 282.738 165.325 275.607 170.194 267.107C173.371 261.561 175.863 253.318 176.505 247.289C177.34 239.45 178.601 231.725 179.002 223.823C179.582 212.411 179.051 200.882 179.051 189.456C179.051 187.316 178.75 177.036 178.17 178.073"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 43"
                                d="M35.0195 221.525C41.2734 226.005 42.2734 229.005 53.2734 228.505C60.3411 228.505 67.2734 222.005 70.095 218.602"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 149"
                                d="M130.273 216.428C136.527 220.909 137.527 223.909 148.527 223.409C155.595 223.409 162.527 216.909 165.349 213.505"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 45"
                                d="M90.5547 279.318C96.0301 284.793 97.8339 288.087 105.657 288.087C111.372 288.087 112.341 281.799 116.861 280.292"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 46"
                                d="M98.7734 231.005C97.7735 233.505 94.2735 241.005 97.2737 245.005C97.5651 246.025 101.241 247.171 101.773 246.505C104.273 245.505 105.273 243.886 105.273 241.005"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </g>
                        <g id="Colour lines">
                            <path
                                id="Vector 47"
                                d="M31.9573 192.968C28.1198 183.713 24.9974 175.196 24.7279 164.954C24.5156 156.887 23.8242 148.754 23.8242 140.555C23.8242 132.916 28.606 126.164 32.1581 119.77C35.5618 113.644 38.6861 108.523 43.7051 103.504"
                                stroke="#FFC2E7"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 48"
                                d="M30.1503 115.252C23.4733 114.162 9.26897 113.679 5.95192 106.215C0.615109 94.2075 15.1977 101.732 17.2981 93.5639C18.7357 87.9731 16.5177 72.948 25.632 71.8757C29.7663 71.3893 30.5346 72.0892 34.6687 74.3859C38.6293 76.5863 41.9909 79.649 47.1194 78.0006C51.9644 76.4433 53.4068 74.5867 58.8671 74.5867C63.561 74.5867 68.7698 78.3377 70.6149 82.7198C72.4495 87.0769 69.23 91.741 67.9039 95.5721C65.9214 101.299 68.9457 101.501 72.4223 105.111C84.1985 117.34 65.5962 128.446 54.7504 128.807C42.4916 129.216 36.0982 125.04 29.2467 115.252"
                                stroke="#F6C4DE"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 49"
                                d="M30.1494 116.156C28.3919 108.345 25.4007 99.777 28.1412 91.5556C29.6285 87.0937 31.095 83.528 36.0233 82.7698C40.1479 82.1353 44.1167 84.0065 45.5118 88.1919C47.222 93.3224 51.6773 94.7175 55.6531 97.5801C58.7162 99.7855 60.4649 107.528 57.7115 110.282C52.9408 115.052 50.6996 115.252 43.7045 115.252C39.7426 115.252 36.0441 117.059 31.9567 117.059"
                                stroke="#F6C4DE"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 51"
                                d="M23.8247 153.206C14.8838 145.968 7.07092 138.617 8.46218 126.096C8.5731 125.098 17.5513 131.413 18.4026 132.623C22.5906 138.574 22.921 145.229 22.921 152.303"
                                stroke="#F6C4DE"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 52"
                                d="M24.7287 171.28C30.4427 165.566 38.9584 155.815 40.7941 147.784C41.4233 145.031 42.481 141.243 41.8483 138.396C41.6486 137.497 35.5324 139.531 34.87 140.555C32.0844 144.86 27.146 146.813 24.9295 151.8C22.5357 157.187 21.971 163.957 24.7287 169.472"
                                stroke="#F6C4DE"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 68"
                                d="M57.1328 182.233C57.1328 177.628 58.606 174.387 61.3321 170.701C65.2162 165.45 68.9867 160.251 72.3148 154.615C74.885 150.262 77.0805 145.842 78.7753 141.048C80.6211 135.827 82.3889 130.373 83.7337 124.994C84.836 120.584 85.4673 115.73 85.6072 111.185C85.7565 106.33 87.2157 101.635 87.3515 96.7454C87.5104 91.024 87.377 85.3107 86.9316 79.6091C86.576 75.0584 86.8472 70.1373 84.7512 65.9453"
                                stroke="#719DF8"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 69"
                                d="M80.0987 44.7226C79.4066 41.0888 76.9992 33.8845 81.407 31.5111C82.8592 30.7291 83.8133 31.3991 84.7503 32.577C86.2433 34.454 86.942 35.6516 89.4664 35.7103C90.8252 35.7419 92.4111 36.3389 93.4718 37.1639C94.3525 37.8489 96.0883 39.2715 96.0883 40.5234C96.0883 44.2098 94.7424 48.0785 90.8554 49.3742"
                                stroke="#719DF8"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 70"
                                d="M76.9 30.7682C73.5297 30.1263 70.219 30.6388 68.1946 33.6754C67.4299 34.8224 66.9823 36.6116 67.3708 37.9716C67.8055 39.4928 68.5395 40.6436 68.0492 42.3324C67.515 44.1725 65.9527 45.7953 64.9966 47.4685C64.1605 48.9317 65.091 50.6374 65.7073 52.0069C66.5245 53.823 68.2442 55.0866 70.2134 55.4148C71.4877 55.6272 72.9325 55.5209 74.2189 55.4794C75.6596 55.4329 76.5352 54.0999 77.4814 53.1536"
                                stroke="#719DF8"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 71"
                                d="M88.7725 35C89.7006 30.8234 95.7145 28.6306 99.8195 29.3147C102.963 29.8386 99.618 33.8387 102.272 35C103.156 35.3865 107.595 35.8713 108.525 36.0659C110.152 36.4065 111.157 38.9017 111.432 40.3621C112.548 46.2757 109.251 50.7881 103.938 53.4445"
                                stroke="#719DF8"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 72"
                                d="M73.4114 57.8052C68.9452 60.6374 67.7243 69.1432 74.8488 69.1432C77.324 69.1432 77.4865 70.4356 78.2082 72.7611C78.6726 74.2574 79.4696 75.5656 80.9539 76.2336C83.398 77.3334 85.755 74.3314 86.754 72.5553"
                                stroke="#719DF8"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 73"
                                d="M90.9418 49.8235C91.9248 49.6234 97.9307 49.4661 98.9337 49.8234C100.296 50.3088 100.471 53.2752 100.563 54.5044C100.618 55.2426 100.983 56.7697 101.624 57.1883C102.307 57.6338 103.186 57.8925 103.508 58.754C105.158 63.1624 102.637 67.1519 99.3373 69.4739C97.5308 70.7453 95.664 71.5648 93.6119 72.2697C92.3007 72.7201 90.9558 73.1111 89.5609 73.0046C87.5846 72.8537 87.1887 72.2445 86.2871 70.688"
                                stroke="#719DF8"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 74"
                                d="M110.155 48.0825C112.544 47.1501 112.221 47.4189 114.016 48.4162C114.906 48.9107 115.555 49.5815 115.709 50.6569C115.995 52.6577 116.303 53.9779 115.637 55.9011C114.9 58.0316 114.495 59.8276 115.303 62.051C115.724 63.2073 116.874 63.8716 117.449 65.0545C118.218 66.6379 118.307 68.2327 118.307 69.9649C118.307 75.6778 111.322 77.6882 106.698 77.6882C104.615 77.6882 102.605 77.6798 100.715 76.7347C97.6879 75.2211 97.2398 74.5655 96.5576 71.1547"
                                stroke="#719DF8"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 75"
                                d="M72.3969 30.92C71.9166 27.9179 70.9623 22.3387 75.6626 22.3387C77.5398 22.3387 79.7193 23.8323 81.598 23.1491C83.3377 22.5165 84.465 19.281 85.698 18.048C88.3791 15.3669 95.6921 16.5796 98.3317 18.7393C100.498 20.5115 104.692 28.5313 100.286 29.6328"
                                stroke="#719DF8"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 76"
                                d="M65.5316 38.214C63.1261 37.2403 54.7329 34.2822 53.184 38.3093C52.4714 40.1621 54.1016 41.7384 55.234 42.9337C56.5651 44.3387 55.8503 46.0702 55.4485 47.6773C54.1919 52.704 55.127 55.9929 59.5009 58.9999C64.0011 62.0938 66.8645 61.2669 71.8152 59.0166"
                                stroke="#719DF8"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 77"
                                d="M56.5212 35.6397C52.5494 33.058 51.815 28.3293 53.4223 24.1502C54.0021 22.6427 56.6046 22.197 57.9991 21.695C60.5977 20.7595 61.3509 19.3163 62.8618 17.1898C65.3092 13.7453 69.3127 9.7094 74.1129 12.041C77.7819 13.823 80.9481 16.921 81.4071 21.0514"
                                stroke="#719DF8"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 78"
                                d="M64.1491 147.626C63.0725 152.172 64.8353 156.881 66.8189 160.927C69.7636 156.51 70.662 149.214 68.7258 144.813C68.6395 144.617 66.2663 140.443 65.9846 141.429C65.3959 143.489 64.6458 145.529 64.1491 147.626Z"
                                stroke="#719DF8"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 79"
                                d="M74.9962 159.817C72.469 161.225 70.5624 160.959 67.7022 161.05C69.377 157.94 75.7879 149.274 79.7273 148.91C82.0929 148.692 83.0572 148.043 85.4257 149.058C86.7866 149.642 81.2585 155.714 80.7761 156.131C78.9256 157.732 77.1335 158.626 74.9962 159.817Z"
                                stroke="#719DF8"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 80"
                                d="M76.213 122.272C76.2949 125.209 80.584 129.003 82.5525 130.912C82.0123 128.292 82.4122 125.646 81.8143 123.036C81.3883 121.177 79.1519 115.973 76.6465 115.973C75.5785 115.973 76.1858 121.293 76.213 122.272Z"
                                stroke="#719DF8"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 81"
                                d="M93.9108 122.414C90.9249 126.077 85.3971 129.074 81.6191 131.656C82.6875 126.988 85.1031 117.782 89.1735 115.938C90.9534 115.132 93.7786 115.408 95.6361 116.153C96.9302 116.671 94.2926 121.946 93.9108 122.414Z"
                                stroke="#719DF8"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 82"
                                d="M77.1396 85.2685C75.5731 90.751 82.2499 98.1493 86.5557 100.429C85.9207 96.3011 84.9282 91.6381 81.9308 88.5103C81.5799 88.1442 77.2774 84.786 77.1396 85.2685Z"
                                stroke="#719DF8"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 83"
                                d="M92.8237 86.094C89.3616 88.4021 86.6158 97.643 87.3144 102.633C89.8062 102.633 91.1518 101.668 92.8237 99.8203C95.2343 97.1559 95.4666 94.1814 95.4666 90.7626C95.4666 88.8558 96.0301 87.1826 95.4666 85.3511C95.2189 84.5463 92.8237 86.094 92.8237 86.094Z"
                                stroke="#719DF8"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 84"
                                d="M140.909 190.202C142.107 184.477 150.987 172.126 153.826 167.03C155.538 163.955 167.656 145.384 169.892 114.826C170.405 107.818 171.084 100.587 171.084 93.5637"
                                stroke="#9A59A5"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 85"
                                d="M143.621 183.239C141.893 179.318 139.387 174.55 138.568 170.272C137.426 164.309 138.957 157.894 139.307 151.941C139.415 150.101 139.76 148.275 139.76 146.434C139.76 145.682 140.669 147.739 140.856 148.079C142.519 151.102 145.129 153.586 146.958 156.541C148.169 158.497 147.912 155.931 147.912 154.92C147.912 152.713 147.545 150.41 147.387 148.198C146.971 142.372 146.462 136.037 147.292 130.225C147.739 127.099 148.721 124.069 149.104 120.929C149.373 118.722 149.379 115.755 150.391 113.73C150.762 112.988 151.848 116.508 151.964 116.924C152.455 118.677 153.023 120.191 153.68 121.882C154.37 123.655 155.844 127.515 155.611 123.098C155.305 117.288 153.912 111.616 154.443 105.768C155.723 91.6881 162.964 79.9205 171.248 68.7256C172.514 67.0149 173.05 64.9869 174.18 63.2908C174.505 62.8036 175.517 67.5028 175.587 67.796C176.902 73.348 178.16 78.7083 179.663 84.2197C180.938 88.895 183.392 101.967 181.525 106.636"
                                stroke="#9A59A5"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 86"
                                d="M144.644 184.133C148.352 183.339 151.5 182.245 154.992 180.879C158.865 179.364 162.189 176.597 165.599 174.324C170.664 170.947 177.064 163.84 177.971 157.495C176.007 158.196 170.467 158.782 168.34 158.782C168.199 158.782 168.675 158.577 168.746 158.52C169.586 157.833 181.992 149.586 183.392 143.05C186.66 133.246 184.644 130.583 184.793 126.71C182.459 126.71 176.238 132.97 177.518 129.701C178.777 126.484 179.55 123.543 180.307 120.214C180.74 118.307 181.525 108.178 181.525 106.636"
                                stroke="#9A59A5"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 87"
                                d="M122.608 173.986C122.608 165.382 122.608 156.778 122.608 148.174C122.608 134.787 126.946 120.9 130.575 108.098C134.778 93.2707 141.306 80.2826 149.927 67.51C152.849 63.1807 155.622 59.1706 159.67 55.7751C162.375 53.507 164.637 51.6553 166.211 48.5081"
                                stroke="#F7C89D"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 88"
                                d="M121.156 162.843C117.054 159.458 114.746 155.295 112.92 150.354C112.74 149.867 111.912 145.879 112.22 145.967C112.992 146.188 114.332 149.163 114.75 149.87C117.211 154.034 117.474 159.257 120.187 163.327"
                                stroke="#F7C89D"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 89"
                                d="M122.125 136.682C119.871 132.085 118.044 127.616 117.387 122.524C117.285 121.731 116.931 114.887 117.657 117.787C118.568 121.432 119.117 125.215 120.079 128.822C120.453 130.225 120.475 134.161 121.64 134.744"
                                stroke="#F7C89D"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 90"
                                d="M128.422 111.489C126.273 108.396 125.513 104.696 125.138 100.992C125.03 99.9283 125.734 91.6193 126.484 93.7791C128.058 98.3168 128.906 104.742 128.906 109.551"
                                stroke="#F7C89D"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 91"
                                d="M136.174 90.6568C134.505 85.9971 132.637 77.168 135.097 72.2469C136.039 70.3641 136.658 77.6401 136.658 78.1682C136.658 81.2299 135.995 85.3661 137.143 88.2345"
                                stroke="#F7C89D"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 92"
                                d="M145.379 70.7936C144.159 67.5152 143.844 62.9491 144.679 59.4893C144.828 58.8724 146.945 53.8801 147.209 54.6446C148.727 59.0296 145.863 64.8043 145.863 69.3402"
                                stroke="#F7C89D"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 93"
                                d="M156.038 57.7131C156.125 52.5581 158.04 50.3059 160.398 46.0858C160.803 45.3614 163.79 41.9884 163.79 42.9636C163.79 47.716 160.01 51.7065 157.976 55.7752"
                                stroke="#F7C89D"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 94"
                                d="M124.063 154.607C125.817 149.446 128.423 145.661 128.423 140.073C128.423 136.93 125.456 142.438 125.139 143.356C124.082 146.421 123.094 149.892 123.094 153.153"
                                stroke="#F7C89D"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 95"
                                d="M126.484 126.992C129.396 125.259 132.416 123.698 134.828 121.286C135.069 121.046 139.186 117.15 137.816 116.845C133.683 115.927 131.198 124.085 126.969 124.085"
                                stroke="#F7C89D"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 96"
                                d="M133.751 100.346C137.943 99.448 140.174 96.8684 143.548 94.5327C144.153 94.1139 148.475 90.7771 147.962 90.6839C144.783 90.1059 141.95 91.1554 139.349 92.8102C138.041 93.6426 137.299 94.4685 136.416 95.7439C135.968 96.391 134.72 98.548 134.72 98.4085"
                                stroke="#F7C89D"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 97"
                                d="M144.41 79.5141C148.747 79.5141 150.775 78.9771 154.557 76.8495C154.837 76.6919 158.928 73.3227 157.006 73.216C153.688 73.0316 151.65 73.9561 148.77 75.3961C147.729 75.9166 144.41 77.8823 144.41 76.1228"
                                stroke="#F7C89D"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 98"
                                d="M155.067 63.5267C159.282 63.5267 162.24 63.0913 165.618 60.4045C166.532 59.6778 169.896 57.4652 169.575 56.0174C169.02 53.5212 160.276 59.4689 158.943 60.1354"
                                stroke="#F7C89D"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 99"
                                d="M118.059 27.2104C118.523 35.6767 122.103 42.8144 125.727 50.259C125.266 44.7152 127.46 36.0097 126.992 30.3842C126.408 23.3446 125.718 16.7933 122.916 10.2511C122.28 8.76658 119.775 15.4232 119.625 15.7995C117.819 20.3098 117.786 22.2146 118.059 27.2104Z"
                                stroke="#74CFE2"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 100"
                                d="M126.485 48.5081C125.56 42.5001 128.471 37.5484 131.572 32.5206C132.169 31.5524 139.083 20.5147 140.75 21.943C141.431 22.5274 141.035 25.0222 140.992 25.7919C140.828 28.7329 139.745 31.6562 138.273 34.1893C135.14 39.5834 130.96 45.1723 126.969 49.9615"
                                stroke="#74CFE2"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 101"
                                d="M129.849 16.1293C127.749 22.4292 126.745 31.9076 127.371 38.4751C130.556 32.105 136.901 19.8466 137.519 12.6572C137.599 11.7301 138.085 2.49508 136.9 3.02167C135.649 3.57786 134.387 6.1134 133.751 7.16658C132.041 9.99858 130.89 13.0044 129.849 16.1293Z"
                                stroke="#74CFE2"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 102"
                                d="M105.652 114.881C106.702 109.53 109.925 104.594 111.977 99.566C114.377 93.6861 117.525 88.2693 120.133 82.475C125.6 70.3254 125.97 61.3251 125.97 48.2793"
                                stroke="#74CFE2"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 103"
                                d="M25.9309 178.07C19.3143 175.234 13.3704 170.118 10.1166 163.611C9.42832 162.234 7.95789 159.696 7.85744 158.189C7.7941 157.239 10.7716 159.97 11.4721 160.398C20.7378 166.06 25.9832 172.857 28.642 183.492"
                                stroke="#F6C4DE"
                                stroke-width="5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </g>
                        <g id="Black Lines">
                            <path
                                id="Vector 47_2"
                                d="M29.1565 192.968C25.319 183.713 22.1966 175.196 21.9271 164.954C21.7148 156.887 21.0234 148.754 21.0234 140.555C21.0234 132.916 25.8052 126.164 29.3573 119.77C32.761 113.644 35.8853 108.523 40.9043 103.504"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 48_2"
                                d="M3.15114 106.215C6.46819 113.679 19.7689 114.162 26.4459 115.252C33.2975 125.04 39.6908 129.216 51.9496 128.807C62.7954 128.446 81.3977 117.34 69.6215 105.111C66.1449 101.501 63.1206 101.299 65.1031 95.5721C66.4292 91.741 69.6487 87.0769 67.8141 82.7198C65.969 78.3377 60.7602 74.5867 56.0664 74.5867C50.606 74.5867 49.1636 76.4433 44.3186 78.0006C39.1901 79.649 35.8286 76.5863 31.8679 74.3859C27.7338 72.0892 26.9655 71.3893 22.8312 71.8757C13.7169 72.948 15.9349 87.9731 14.4973 93.5639C12.3969 101.732 -2.18567 94.2075 3.15114 106.215Z"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 49_2"
                                d="M25.3411 91.5556C22.6006 99.7771 27.3985 109.249 29.1559 117.059C33.2433 117.059 36.9425 115.252 40.9044 115.252C47.8995 115.252 50.1407 115.052 54.9114 110.282C57.6649 107.528 55.9161 99.7855 52.853 97.5801C48.8772 94.7175 44.422 93.3224 42.7118 88.1919C41.3166 84.0065 37.3479 82.1353 33.2232 82.7698C28.2949 83.528 26.8284 87.0937 25.3411 91.5556Z"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 51_2"
                                d="M5.43483 125.083C4.04357 137.605 11.8565 144.956 20.7973 152.194C20.7973 145.12 18.2348 139.439 14.0469 133.487C13.2185 132.365 5.54576 124.085 5.43483 125.083Z"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 52_2"
                                d="M37.7734 151C35.9378 159.031 27.642 165.566 21.928 171.28C21.2734 145.5 38.8479 137.497 39.0476 138.396C39.0476 141.5 38.4027 148.247 37.7734 151Z"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 68_2"
                                d="M54.332 182.233C54.332 177.628 55.8052 174.387 58.5313 170.701C62.4154 165.45 66.186 160.251 69.5141 154.615C72.0842 150.262 74.2797 145.842 75.9745 141.048C77.8203 135.827 79.5881 130.373 80.9329 124.994C82.0352 120.584 82.6666 115.73 82.8064 111.185C82.9558 106.33 84.4149 101.635 84.5507 96.7454C84.7096 91.024 84.5762 85.3107 84.1308 79.6091C83.7753 75.0584 84.0464 70.1373 81.9504 65.9453"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 69_2"
                                d="M77.298 44.7226C76.6058 41.0888 74.1984 33.8845 78.6062 31.5111C80.0584 30.7291 81.0125 31.3991 81.9495 32.577C83.4425 34.454 84.1412 35.6516 86.6656 35.7103C88.0244 35.7419 89.6103 36.3389 90.6711 37.1639C91.5518 37.8489 93.2875 39.2715 93.2875 40.5234C93.2875 44.2098 91.9416 48.0785 88.0546 49.3742"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 70_2"
                                d="M74.0992 30.7682C70.7289 30.1263 67.4182 30.6388 65.3938 33.6754C64.6291 34.8224 64.1815 36.6116 64.5701 37.9716C65.0047 39.4928 65.7387 40.6436 65.2484 42.3324C64.7142 44.1725 63.152 45.7953 62.1959 47.4685C61.3597 48.9317 62.2902 50.6374 62.9065 52.0069C63.7237 53.823 65.4434 55.0866 67.4127 55.4148C68.687 55.6272 70.1318 55.5209 71.4181 55.4794C72.8589 55.4329 73.7344 54.0999 74.6806 53.1536"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 71_2"
                                d="M88.7725 35.5C89.7006 31.3234 92.9137 28.6306 97.0187 29.3148C100.162 29.8386 100.211 34.6785 102.865 35.8398C103.749 36.2263 104.795 35.8714 105.724 36.0659C107.352 36.4065 108.356 38.9017 108.631 40.3621C109.747 46.2757 106.45 50.7882 101.137 53.4445"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 72_2"
                                d="M70.6106 57.8052C66.1444 60.6374 64.9235 69.1432 72.048 69.1432C74.5232 69.1432 74.6858 70.4356 75.4075 72.7611C75.8718 74.2574 76.6688 75.5656 78.1531 76.2336C80.5973 77.3334 82.9542 74.3314 83.9532 72.5553"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 73_2"
                                d="M92.6349 50.0629C93.6179 49.8628 95.1299 49.4659 96.1329 49.8232C97.4953 50.3086 97.6703 53.275 97.7623 54.5042C97.8176 55.2424 98.1827 56.7696 98.8236 57.1881C99.5057 57.6336 100.385 57.8923 100.707 58.7538C102.357 63.1622 99.8358 67.1517 96.5365 69.4737C94.73 70.7451 92.8632 71.5646 90.8112 72.2695C89.4999 72.7199 88.155 73.1109 86.7601 73.0044C84.7838 72.8535 84.3879 72.2443 83.4863 70.6878"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 74_2"
                                d="M107.772 48C111.772 48 113.502 53.9777 112.836 55.9009C112.099 58.0315 111.694 59.8274 112.503 62.0509C112.923 63.2072 114.073 63.8715 114.648 65.0543C115.417 66.6377 115.506 68.2326 115.506 69.9648C115.506 75.6776 108.521 77.688 103.897 77.688C101.814 77.688 99.8047 77.6797 97.9144 76.7345C94.8872 75.2209 94.439 74.5653 93.7568 71.1546"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 75_2"
                                d="M69.2734 30.4998C68.7931 27.4977 68.1612 22.3385 72.8615 22.3385C74.7387 22.3385 76.9182 23.8321 78.7969 23.149C80.5366 22.5164 81.6639 19.2809 82.8969 18.0478C85.578 15.3668 92.7734 17 95.5306 18.7391C97.9816 20.2852 102.179 28.3984 97.7734 29.4998"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 76_2"
                                d="M64.2734 38.5C61.8679 37.5263 51.9321 34.2822 50.3832 38.3094C49.6707 40.1621 51.3008 41.7384 52.4332 42.9338C53.7643 44.3387 53.0495 46.0702 52.6478 47.6773C51.3911 52.704 52.3262 55.9929 56.7001 58.9999C61.2003 62.0939 64.0637 61.2669 69.0144 59.0166"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 77_2"
                                d="M53.7204 35.6397C49.7486 33.058 49.0142 28.3293 50.6216 24.1502C51.2014 22.6427 53.8039 22.197 55.1983 21.695C57.7969 20.7595 58.5501 19.3163 60.061 17.1898C62.5084 13.7453 66.5119 9.7094 71.3121 12.041C74.9811 13.823 77.8135 18.3697 78.2725 22.5"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 78_2"
                                d="M61.3483 147.626C60.2718 152.172 62.0345 156.881 64.0181 160.927C66.9628 156.51 67.8612 149.214 65.9251 144.813C65.8387 144.617 63.4655 140.443 63.1838 141.429C62.5951 143.489 61.8451 145.529 61.3483 147.626Z"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 79_2"
                                d="M72.1954 159.817C69.6682 161.225 67.7617 160.959 64.9015 161.05C66.5762 157.94 72.9872 149.274 76.9265 148.91C79.2921 148.692 80.2564 148.043 82.6249 149.058C83.9858 149.642 78.4577 155.714 77.9754 156.131C76.1248 157.732 74.3327 158.626 72.1954 159.817Z"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 80_2"
                                d="M74.2734 122C75.2734 125.5 77.7833 129.002 79.7518 130.912C79.2116 128.292 79.6115 125.645 79.0135 123.036C78.5876 121.177 76.3512 115.972 73.8458 115.972C72.7778 115.972 74.2462 121.021 74.2734 122Z"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 81_2"
                                d="M91.1101 122.414C88.1241 126.076 82.5963 129.074 78.8183 131.656C79.8867 126.988 82.3023 117.782 86.3727 115.938C88.1526 115.131 90.9778 115.408 92.8353 116.153C94.1294 116.671 91.4918 121.946 91.1101 122.414Z"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 82_2"
                                d="M74.3388 85.2685C72.7723 90.751 79.4491 98.1493 83.7549 100.429C83.1199 96.3011 82.1275 91.6381 79.13 88.5103C78.7792 88.1442 74.4766 84.786 74.3388 85.2685Z"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 83_2"
                                d="M90.0229 86.094C86.5609 88.4021 83.815 97.643 84.5136 102.633C87.0055 102.633 88.351 101.668 90.0229 99.8203C92.4336 97.1559 92.6658 94.1814 92.6658 90.7626C92.6658 88.8558 93.2293 87.1826 92.6658 85.3511C92.4181 84.5463 90.0229 86.094 90.0229 86.094Z"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 84_2"
                                d="M138.108 190.202C139.307 184.477 148.186 172.126 151.025 167.03C152.737 163.955 164.855 145.384 167.091 114.826C167.604 107.818 168.283 100.587 168.283 93.5637"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 85_2"
                                d="M140.82 183.239C139.093 179.318 136.586 174.55 135.767 170.271C134.625 164.309 136.156 157.894 136.506 151.941C136.614 150.101 136.959 148.274 136.959 146.434C136.959 145.682 137.868 147.739 138.055 148.079C139.718 151.102 142.328 153.586 144.158 156.541C145.369 158.497 145.111 155.931 145.111 154.92C145.111 152.713 144.745 150.41 144.587 148.198C144.17 142.371 143.661 136.036 144.491 130.225C144.938 127.098 145.92 124.069 146.303 120.929C146.572 118.722 146.578 115.754 147.59 113.73C147.961 112.987 149.047 116.508 149.163 116.924C149.654 118.677 150.222 120.19 150.88 121.882C151.569 123.655 153.043 127.514 152.81 123.098C152.505 117.287 151.111 111.616 151.642 105.768C152.922 91.6878 160.163 79.9203 168.448 68.7254C169.714 67.0146 170.249 64.9867 171.38 63.2905C171.704 62.8033 172.717 67.5026 172.786 67.7957C174.101 73.3477 175.359 78.7081 176.862 84.2195C178.137 88.8947 180.591 101.967 178.724 106.635"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 86_2"
                                d="M141.843 184.133C145.551 183.338 148.699 182.245 152.191 180.879C156.064 179.363 159.388 176.597 162.798 174.324C167.863 170.947 174.263 163.839 175.17 157.495C173.207 158.196 167.666 158.782 165.54 158.782C165.398 158.782 165.875 158.577 165.945 158.52C166.785 157.832 179.191 149.586 180.591 143.05C183.859 133.246 181.843 130.583 181.992 126.71C179.658 126.71 173.438 132.97 174.717 129.701C175.976 126.483 176.749 123.543 177.506 120.213C177.939 118.307 178.724 108.178 178.724 106.635"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 87_2"
                                d="M119.808 173.986C119.808 165.382 119.808 156.778 119.808 148.174C119.808 134.787 124.145 120.9 127.774 108.098C131.977 93.2707 138.505 80.2826 147.126 67.51C150.049 63.1807 152.821 59.1706 156.87 55.7751C159.574 53.507 161.836 51.6553 163.41 48.5081"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 88_2"
                                d="M112.544 150.607C113.813 155.719 115.509 159.95 119.213 163.767C116.966 159.422 117.069 154.797 115.083 150.386C114.746 149.637 113.04 146.135 112.195 146.008C111.898 145.886 112.419 150.103 112.544 150.607Z"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 89_2"
                                d="M116.308 123.758C117.025 128.673 118.314 131.063 120.773 135.5C120.773 134.5 121.273 130.998 121.273 128.102C121.219 124.24 120.673 119.414 117.399 117C116.309 117 116.196 122.992 116.308 123.758Z"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 90_2"
                                d="M122.337 100.992C122.712 104.696 123.472 108.396 125.621 111.489C125.621 106.68 125.257 98.3168 123.683 93.7791C122.934 91.6193 122.229 99.9283 122.337 100.992Z"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 91_2"
                                d="M133.373 90.657C131.704 85.9973 130.314 76.9211 132.774 72.0001C133.715 70.1172 134.273 77.9718 134.273 78.5C134.773 81.5 132.225 87.7886 133.373 90.657Z"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 92_2"
                                d="M141.878 59.4894C141.043 62.9492 142.555 68.7218 143.774 72.0002C143.774 67.4643 145.926 59.0298 144.408 54.6447C144.144 53.8803 142.713 56.0295 141.878 59.4894Z"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 93_2"
                                d="M157.598 46.0858C155.239 50.3059 153.325 52.5581 153.237 57.7131C155.272 53.6444 160.989 47.716 160.989 42.9636C160.989 41.9884 158.002 45.3614 157.598 46.0858Z"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 94_2"
                                d="M125.622 140.073C125.622 145.661 123.773 148.5 120.293 153.153C120.293 149.892 121.282 146.421 122.339 143.356C122.655 142.438 125.622 136.93 125.622 140.073Z"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 95_2"
                                d="M131.617 121.027C129.205 123.439 126.185 125 123.273 126.733C125.363 125.241 129.863 113.241 134.605 116.586C135.975 116.891 131.858 120.786 131.617 121.027Z"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 96_2"
                                d="M140.747 94.5327C137.374 96.8684 135.143 99.448 130.95 100.346C130.95 100.486 133.167 96.391 133.615 95.7439C134.498 94.4685 135.24 93.6426 136.549 92.8102C139.149 91.1554 141.983 90.1059 145.161 90.6839C145.674 90.7771 141.352 94.1139 140.747 94.5327Z"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 97_2"
                                d="M141.609 79.5141C145.947 79.5141 147.974 78.9771 151.756 76.8495C152.037 76.6919 156.128 73.3228 154.206 73.216C150.887 73.0317 150.153 73.56 147.273 75C146.232 75.5205 141.273 80.7595 141.273 79"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 98_2"
                                d="M162.817 60.4046C159.44 63.0913 156.482 63.5267 152.267 63.5267C153.6 62.8603 166.219 53.5213 166.774 56.0175C167.096 57.4653 163.731 59.6779 162.817 60.4046Z"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 99_2"
                                d="M115.258 27.2104C115.722 35.6767 119.302 42.8144 122.926 50.259C122.466 44.7152 124.659 36.0097 124.192 30.3842C123.607 23.3446 122.917 16.7933 120.115 10.2511C119.479 8.76658 116.975 15.4232 116.824 15.7995C115.019 20.3098 114.985 22.2146 115.258 27.2104Z"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 100_2"
                                d="M123.684 48.5081C122.76 42.5001 125.67 37.5484 128.771 32.5206C129.368 31.5524 136.282 20.5147 137.949 21.943C138.631 22.5274 138.234 25.0222 138.191 25.7919C138.028 28.7329 136.944 31.6562 135.473 34.1893C132.339 39.5834 128.159 45.1723 124.168 49.9615"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 101_2"
                                d="M127.048 16.1293C124.948 22.4292 123.944 31.9076 124.57 38.4751C127.755 32.105 134.101 19.8466 134.719 12.6572C134.798 11.7301 135.284 2.49508 134.099 3.02167C132.848 3.57786 131.586 6.1134 130.95 7.16658C129.241 9.99858 128.089 13.0044 127.048 16.1293Z"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 102_2"
                                d="M102.852 114.88C103.901 109.529 107.124 104.594 109.177 99.5657C111.577 93.6859 114.724 88.269 117.332 82.4747C122.799 70.3252 123.169 61.3248 123.169 48.2791"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                id="Vector 50"
                                d="M6.38224 163.611C9.63596 170.118 16.5799 175.234 23.1966 178.069C20.5378 167.434 17.7744 166 8.73775 160.397C8.0093 159.946 4.1221 157.756 4.12305 158.189C4.2235 159.695 5.69393 162.234 6.38224 163.611Z"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </g>
                    </g>
                </svg>
            </header>
            <BlogList />
            <div className="home-work-container" id="work">
                <h4 className="home-header">Work</h4>
                <p>
                    I work at Shopify as a frontend developer. My work includes
                    writing code for the core admin product with a strong focus
                    on accessibility and performance.
                </p>
                <img
                    src={work}
                    style={{ width: "100%" }}
                    alt="Shopify's motto - make commerce better for everyone"
                />
                <p>
                    You can also view my
                    <Link to="/work"> past work case studies here </Link> or
                    other projects on{" "}
                    <a
                        href="https://github.com/ananyaneogi"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        github.
                    </a>
                </p>
            </div>

            <div class="newsletter-homepage">
                <div class="newsletter-home-container">
                    <h4 className="home-header">Sign up for the newsletter</h4>
                    <p>
                        I maintain an occasional newsletter to share what I
                        learn while I navigate the deep trenches of software
                        development.
                    </p>
                    <NewsletterForm />
                </div>
            </div>
        </div>
    )
}
