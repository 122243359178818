import React from "react"
import { Link } from "gatsby"

const BlogCard = props => {
    return (
        <div className="blog-card">
            <div className="blog-image">
                <img src={props.src} alt="" />
            </div>
            <div className="project-details">
                <h2>{props.title}</h2>
                <p>{props.description}</p>
                <Link to={props.link} className="button-link">
                    Read post
                </Link>
            </div>
        </div>
    )
}

export default BlogCard
