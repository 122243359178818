import React, { Component } from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import DocumentTitle from "react-document-title"

import "../utils/theme.scss"

import Home from "../components/Home"
import Layout from "../components/layout"

class App extends Component {
    render() {
        return (
            <Layout>
                <DocumentTitle title="Ananya Neogi - Home">
                    <TransitionGroup>
                        <CSSTransition timeout={300} classNames="fade">
                            <div className="main-content">
                                <Home />
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </DocumentTitle>
            </Layout>
        )
    }
}

export default App
